import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';

// Components
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import UpdateIcon from '@material-ui/icons/Create';
import { editSucursal } from '../services/SucursalService';
import { makeStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { Modal } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    boxRobot: {
        height: 400
    },
    dialogWrapper: {
        margin: theme.spacing(2, 2, 8, 60),
        position: 'absolute',
        bottom: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        width: 400,
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1030,
    },
    paginate: {
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1)
    },
    select: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    container: {
        maxHeight: '70vh',
    },
    paper: {
        margin: theme.spacing(13, 2, 2, 60),


    },
}));

const ButtonEditSucursal = ({ sucursal, OnEdit }) => {

    const [Code, setCode] = useState(sucursal.Code);
    const [ID, setID] = useState(sucursal.ID);
    const [Name, setName] = useState(sucursal.Name);
    const [Address, setAddress] = useState(sucursal.Address);
    const [Telephone, setTelephone] = useState(sucursal.Telephone);
    const [Email, setEmail] = useState(sucursal.Email);
    const [Contact, setContact] = useState(sucursal.Contact);
    const [openModal, setOpenModal] = useState(false);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const sendNotification = (variant, message) => {
        enqueueSnackbar(message, { variant });
    };
    const UpdateSucursal = async (evt) => {
        try {
            const dato = await editSucursal(ID, Code, Name, Address, Telephone, Email, Contact);
            OnEdit({ ID, Code, Name, Address, Telephone, Email, Contact });
            setOpenModal(false);
            sendNotification('success', "Sucursal modificada correctamente");
            return;

        } catch (e) {
            sendNotification('error', e.message);
        }
    }

    return (
        <>
        <IconButton
              aria-label="update"
              className={classes.margin}
              onClick={() => { handleOpenModal(sucursal) }}
        >
        <UpdateIcon />
        </IconButton>
            <Dialog

                
            open={openModal}
                onClose={handleCloseModal}>
                <DialogTitle id="alert-dialog-title">{"Modificar Sucursal"}</DialogTitle>
                <DialogContent>
                    <Grid container xs={10} justify="center" >
                        <Grid item xs={10}>
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="code"
                                label="Codigo"
                                id="code"
                                autoComplete="code"
                                autoFocus
                                value={Code}
                                onChange={(evt) => setCode(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                id="name"
                                label="Nombre"
                                name="name"
                                autoComplete="name"
                                value={Name}
                                onChange={(evt) => setName(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="address"
                                label="Direccion"
                                id="address"
                                autoComplete="address"
                                value={Address}
                                onChange={(evt) => setAddress(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                id="telephone"
                                label="Telefono"
                                name="telephone"
                                autoComplete="telephone"
                                value={Telephone}
                                onChange={(evt) => setTelephone(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="email"
                                label="Email"
                                id="email"
                                autoComplete="email"
                                value={Email}
                                onChange={(evt) => setEmail(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="contact"
                                label="Contacto"
                                id="contact"
                                autoComplete="contact"
                                value={Contact}
                                onChange={(evt) => setContact(evt.target.value)}
                            />

                            

                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        width="100"
                        justify="center"
                        variant="contained"
                        color="secondary"
                        onClick={() => { UpdateSucursal() }}

                    >
                        Modificar Sucursal
                            </Button>
                </DialogActions>
                
            </Dialog>
            </>
        )
}
export default ButtonEditSucursal;