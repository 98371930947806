import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

// Pages
import LoggedInPage from './pages/LoggedInPage';

import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import LoginPage from './pages/LoginPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import Sucursales from './pages/SucursalesPage';
import Companies from './pages/CompanyPage';
import Users from './pages/UsersPage';
import Depositos from './pages/DepositosPage';

// Middlewares
import AuthRoute from './middlewares/AuthRoute';
import LoginRoute from './middlewares/LoginRoute';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const App = () => (
    <Router basename={baseUrl}>
        <SnackbarProvider maxSnack={3}>
            <Switch>
                <AuthRoute path='/' exact component={HomePage} />
                <AuthRoute path='/success' component={LoggedInPage} />
                <AuthRoute path='/change-password' exact component={ChangePasswordPage} />
                <AuthRoute path='/sucursales' exact component={Sucursales} />
                <AuthRoute path='/companies' exact component={Companies} />
                <AuthRoute path='/usersAPP' exact component={Users} />
                <AuthRoute path='/depositos' exact component={Depositos} />
                <LoginRoute path='/login' exact component={LoginPage} />
               
                <AuthRoute path='*' exact component={NotFoundPage} />
            </Switch>
        </SnackbarProvider>
    </Router>
);

export default App;
