import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

// Service
import { searchSucursal } from '../services/SucursalService';
import { editDeposito } from '../services/DepositoService';

// Components
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Notification from '../components/Notification';
import IconButton from '@material-ui/core/IconButton';
import UpdateIcon from '@material-ui/icons/Create';



const useStyles = makeStyles(theme => ({
    boxRobot: {
        height: 400
    },
    dialogWrapper: {
        margin: theme.spacing(2, 2, 8, 60),
        position: 'absolute',
        bottom: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        width: 400,
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1030,
    },
    paginate: {
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1)
    },
    select: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    container: {
        maxHeight: '70vh',
    },
    paper: {
        margin: theme.spacing(13, 2, 2, 60),
    },
    formsSelect: {
        margin: theme.spacing(1),
        minWidth: 365,


    },
}));

const ButtonEditDeposito = ({ deposito, OnEdit }) => {

    const [sucursales, setSucursales] = useState(null);
    const [ID, setID] = useState(deposito.ID);
    const [Name, setName] = useState(deposito.Name);
    const [Code, setCode] = useState(deposito.Code);
    const [IdSucursal, setSucu] = useState(deposito.IdSucursal);
    const [nameSucu, setSucuName] = useState(deposito.Sucursal);
    const [openModal, setOpenModal] = useState(false);
    const classes = useStyles();
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
    const rol = localStorage.getItem('akeron-rol');
    const [state, setState] = React.useState({
        Sucursal: '',
        Tipo: '',
    });
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const { enqueueSnackbar } = useSnackbar();

    const sendNotification = (variant, message) => {
        enqueueSnackbar(message, { variant });
    };


    const UpdateDepo = async (evt) => {
        try {
            var dato = await editDeposito(ID, Name, Code, IdSucursal);
            console.log(dato);
            const Sucursal = dato.Sucursal;
            console.log(Sucursal);
            OnEdit({ ID, Name, Code, IdSucursal, Sucursal });
            setOpenModal(false);
            sendNotification('success', "Deposito modificado correctamente");
            return;

        } catch (e) {
            sendNotification('error', e.message);
        }
    }

    const Sucursales = async () => {

        try {
            const data = await searchSucursal();
            if (data !== null) {

                setSucursales(data);


            }
            else {
                setNotify({
                    isOpen: true,
                    message: 'No se pudieron cargar las sucursales',
                    type: 'error'
                })

            }
        } catch (e) {
            setNotify({
                isOpen: true,
                message: e.message,
                type: 'error'
            })
        }



    };






    useEffect(() => {
        Sucursales();
    }, []);

    return (
        <>

            <IconButton
                aria-label="update"
                className={classes.margin}
                onClick={() => { handleOpenModal(deposito) }}
            >
                <UpdateIcon />
            </IconButton>

            <Dialog


                open={openModal}
                onClose={handleCloseModal}>
                <DialogTitle id="alert-dialog-title">{"Modificar Deposito"}</DialogTitle>
                <DialogContent>
                    <Grid container xs={10} justify="center" >
                        <Grid item xs={10}>
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="code"
                                label="Codigo"
                                id="code"
                                autoComplete="code"
                                autoFocus
                                value={Code}
                                onChange={(evt) => setCode(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                id="name"
                                label="Nombre"
                                name="name"
                                autoComplete="name"
                                value={Name}
                                onChange={(evt) => setName(evt.target.value)}
                            />
                            

                            {(rol == 2) &&
                                <FormControl  className={classes.formsSelect}>
                                    <InputLabel htmlFor="age-native-simple">Sucursal *</InputLabel>
                                    {sucursales &&
                                        <Select
                                            native
                                            value={IdSucursal}
                                            color="secondary"
                                            fullWidth
                                            onChange={(evt) => setSucu(evt.target.value)}
                                            inputProps={{
                                                name: 'sucu',
                                                id: 'age-native-simple',
                                            }}
                                        >


                                            <option aria-label="None" value="" />
                                            {sucursales.map((sucursal, i) => {
                                                return (
                                                    <option value={sucursal.ID}>{sucursal.Name}</option>
                                                )
                                            })}



                                        </Select>
                                    }
                                </FormControl>

                            }




                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        width="100"
                        justify="center"
                        variant="contained"
                        color="secondary"
                        onClick={() => { UpdateDepo() }}

                    >
                        Modificar Deposito
                            </Button>
                </DialogActions>
                <Notification
                    notify={notify}
                    setNotify={setNotify}
                />

            </Dialog>
        </>
    )
}
export default ButtonEditDeposito;