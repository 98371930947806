import axios from 'axios';
import config from '../configs/config.json';


const newSecureClient = () => {
    
    return axios.create({
        baseURL: config.baseURL,
        timeout: config.timeout,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('akeron-token')}`,
        }
    });
};

export const insertSucursal = async (code, name,  address, telephone, email, contact) => {
    const secureclient = newSecureClient();
    
    return await secureclient.post(`/sucursal/CreateSucursal`, {
        code, name, address, telephone, email, contact
    })
        .then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                console.log('error');
                throw new Error('No se pudo agregar la sucursal');
                
            }
        }).catch((e) => {  throw new Error(e.response.data.message); });
};

export const editSucursal = async (ID, code, name, address, telephone, email, contact) => {
    const secureclient = newSecureClient();
   
    return await secureclient.put(`/sucursal/UpdateSucursal`, {
        ID, code, name, address, telephone, email, contact
    })
        .then(response => {
            if (response.status === 200) {
                return;
            } else {
                throw new Error('No se pudo modificar la sucursal');
            }
        }).catch((e) => { throw new Error(e.response.data.message); });
};

export const deleteSucursal = async (ID) => {
    
    const secureclient = newSecureClient();
    return await secureclient.post(`/sucursal/DeleteSucursal`, {
        ID
    })
        .then(response => {
            if (response.status === 200) {
                return;
            } else {
                throw new Error('No se pudo eliminar la sucursal');
            }
        }).catch((e) => { throw new Error(e.response.data.message); });
};

export const searchSucursal = async () => {
    const secureclient = newSecureClient();
    return await secureclient.get(`/sucursal/getall`, {})
        .then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('No se pudo listar las sucursales');
            }
        }).catch((e) => { throw new Error(e.response.data.message); });
};