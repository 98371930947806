import axios from 'axios';
import config from '../configs/config.json';


const newSecureClient = () => {
    
    return axios.create({
        baseURL: config.baseURL,
        timeout: config.timeout,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('akeron-token')}`,
        }
    });
};

export const insertDeposito = async (name, code,  idSucursal) => {
    const secureclient = newSecureClient();

    return await secureclient.post(`/deposito/CreateDeposito`, {
        name, code,  idSucursal
    })
        .then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                console.log('error');
                throw new Error('No se pudo agregar el deposito');

            }
        })
        .catch((e) => { throw new Error(e.response.data.message); });
};

export const editDeposito = async (ID, name, code, idSucursal) => {
    const secureclient = newSecureClient();
   
    return await secureclient.put(`/deposito/UpdateDeposito`, {
        ID, name, code, name, idSucursal
    })
        .then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('No se pudo modificar el deposito');
            }
        }).catch((e) => { throw new Error(e.response.data.message); });
};

export const deleteDeposito = async (ID) => {

    const secureclient = newSecureClient();
    return await secureclient.post(`/deposito/DeleteDeposito`, {
        ID
    })
        .then(response => {
            if (response.status === 200) {
                return ;
            } else {
                throw new Error('No se pudo eliminar el deposito');
            }
        }).catch((e) => { throw new Error(e.response.data.message); });
};

export const searchDepositos = async (idSucursal) => {
    const secureclient = newSecureClient();
    return await secureclient.get(`/deposito/GetBySucursal/${idSucursal}`, {}) 
        .then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('No se pudo listar los depositos de la sucursal');
            }
        }).catch((e) => { throw new Error(e.response.data.message); });
};