import ActionButton from "./ActionButton";
import Button from "./Button";


const Controls = {
    
    Button,
    ActionButton
   
}

export default Controls;