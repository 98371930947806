import React from 'react';

// Components
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Layout from '../components/Layout';
// Styles
import { makeStyles } from '@material-ui/core/styles';
// Assets
import logo from '../assets/img/LogoSommier.png';


const useStyles = makeStyles(theme => ({
    
    img: {
        height: 'auto',
        width: 500,
        marginLeft: theme.spacing(60),
        marginTop: theme.spacing(5)
    }
    
}));

const HomePage = props => {
    const name = 'BIENVENIDO ' + localStorage.getItem('akeron-name');
    const classes = useStyles();
    return (
        <Layout { ...props }>
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Typography align="center" variant="h3" gutterBottom>
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="center" variant="subtitle1" gutterBottom>
                        Usted se encuentra en el administrador de usuarios de <b>AKERON RETAIL</b>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="center" variant="subtitle1" gutterBottom>
                        Dirijase a la barra de navegación, y comience a administrar las sucursales y los usuarios de la aplicación.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <img className={classes.img} src={logo} alt={logo} />
                </Grid>
                
            </Grid>
        </Layout>
    );
}

export default HomePage;