import axios from 'axios';
import config from '../configs/config.json';


const newSecureClient = () => {
   
    return axios.create({
        baseURL: config.baseURL,
        timeout: config.timeout,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('akeron-token')}`,
        }
    });
};

export const insertCompany = async (name, address, telephone, email, contact, nameUser, userName, userPass) => {
    const secureclient = newSecureClient();

    return await secureclient.post(`/company/CreateCompany`, {
        name, address, telephone, email, contact, nameUser, userName, userPass
    })
        .then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                console.log('error');
                throw new Error('No se pudo agregar la Empresa');

            }
        }).catch((e) => { console.log(e); throw new Error('No se pudo agregar la Empresa'); });
};

export const editCompany = async (ID, name, address, telephone, email, contact, nameUser, userName, userPass) => {
    const secureclient = newSecureClient();
    return await secureclient.put(`/Company/UpdateCompany`, {
        ID, name, address, telephone, email, contact, nameUser, userName, userPass
    })
        .then(response => {
            if (response.status === 200) {
                return;
            } else {
                throw new Error('No se pudo modificar la empresa');
            }
        }).catch((e) => { throw new Error('No se pudo modificar la empresa'); });
};

export const deleteCompany = async (ID) => {

    const secureclient = newSecureClient();
    return await secureclient.post(`/company/DeleteCompany`, {
        ID
    })
        .then(response => {
            if (response.status === 200) {
                return;
            } else {
                throw new Error('No se pudo eliminar la empresa');
            }
        }).catch((e) => { throw new Error('No se pudo agregar la empresa'); });
};

export const searchCompany = async () => {
    const secureclient = newSecureClient();
    return await secureclient.get(`/company/getall`, {})
        .then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('No se pudo listar las empresas');
            }
        }).catch((e) => { throw new Error('No se pudo listar las empresas'); });
};