import React, { useState, useEffect, forwardRef } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from "react-router-dom";


// Service

import { searchCompany, deleteCompany } from '../services/CompanyService';

// Components

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import CircularLoadingBackdrop from '../components/animations/loading/CircularLoadingBackdrop';
import Layout from '../components/Layout';
import Typography from '@material-ui/core/Typography';
import Notification from '../components/Notification';
import ConfirmDialog from '../components/ConfirmDialog';
import Popup from '../components/Popup';
import ButtonEditCompany from '../components/ButtonEditCompany';
import ButtonInsertCompany from '../components/ButtonInsertCompany';


// Styles
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    boxRobot: {
        height: 400
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1030,
    },
    paginate: {
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1)
    },
    select: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    container: {
        maxHeight: '70vh',
    },
    paper: {
        margin: theme.spacing(13, 2, 2, 60),


    },
}));

const DEFAULT_BACKDROP = {
    downloading: false,
    label: '',
};


const LIMIT = 10;



const CompanyPage = props => {

    const [companies, setCompanies] = useState(null);
    const [backdrop, setBackdrop] = useState(DEFAULT_BACKDROP);
    const [openPopup, setOpenPopup] = useState(false)
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
        

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const sendNotification = (variant, message) => {
        enqueueSnackbar(message, { variant });
    };

    const Empresas = async () => {
        setBackdrop({ downloading: true, label: 'Buscando Empresas' });
        try {
            const data = await searchCompany();
            if (data !== null) {

                setCompanies(data);


            }
            else {
                sendNotification('info', 'No se pudieron cargar las empresas');
            }
        } catch (e) {
            sendNotification('error', e.message);
        }

        setBackdrop(DEFAULT_BACKDROP);

    };
    const ActualizarEmpresa = () => {
        //location.reload();
    }

    useEffect(() => {
        Empresas();
    }, []);

    const onDelete = async (id, i) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        const newCompanies = [...companies]
        await deleteCompany(id);
        newCompanies.splice(i, 1);
        setCompanies(newCompanies);

        setNotify({
            isOpen: true,
            message: 'La empresa se borro con exito',
            type: 'success'
        })


    }

    return (
        <Layout {...props}>
            <CircularLoadingBackdrop show={backdrop.downloading} label={backdrop.label} />
            <Grid container justify="center" spacing={1}>
                <Grid item xs={10}>
                    <Typography align="center" variant="h4" gutterBottom>
                        EMPRESAS
                    </Typography>
                    <Grid container justify="flex-end">
                        <ButtonInsertCompany
                            OnEdit={(s) => {

                                ActualizarEmpresa()


                            }}

                        />
                    </Grid>
                </Grid>
                <Grid item xs={10}>


                    {(companies && companies.map) &&
                        <Paper className={classes.root}>
                            <TableContainer className={classes.container}>
                                <Table size="small" stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>

                                            <TableCell align="left">Nombre</TableCell>
                                            <TableCell align="left">Direccion</TableCell>
                                            <TableCell align="left">Telefono</TableCell>
                                            <TableCell align="left">Email</TableCell>
                                            <TableCell align="left">Contacto</TableCell>
                                            <TableCell align="left">Opciones</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {companies.map((company, i) => {

                                            return (
                                                <TableRow key={company.ID}>

                                                    <TableCell align="left">{company.Name}</TableCell>
                                                    <TableCell align="left">{company.Address}</TableCell>
                                                    <TableCell align="left">{company.Telephone}</TableCell>
                                                    <TableCell align="left">{company.Email}</TableCell>
                                                    <TableCell align="left">{company.Contact}</TableCell>
                                                    <TableCell align="left">
                                                        <IconButton
                                                            aria-label="delete"
                                                            className={classes.margin}
                                                            onClick={() => {
                                                                setConfirmDialog({
                                                                    isOpen: true,
                                                                    title: '¿Esta seguro que desea eliminar la empresa?',
                                                                    subTitle: "",
                                                                    onConfirm: () => { onDelete(company.ID, i) }
                                                                })
                                                            }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        <ButtonEditCompany
                                                            OnEdit={(s) => {
                                                                const newCompany = [...companies]
                                                                newCompany[i] = s
                                                                setCompanies(newCompany)
                                                            }}
                                                            company={company}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Paper>
                    }

                </Grid>
            </Grid>
            <Popup
                title="Editar Empresa"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            />
            <Notification
                notify={notify}
                setNotify={setNotify}
            />

            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </Layout>
    );
};

export default CompanyPage;