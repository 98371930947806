import axios from 'axios';
import config from '../configs/config.json';


const newSecureClient = () => {
    console.log('LLEGA AL new secure');
    return axios.create({
        baseURL: config.baseURL,
        timeout: config.timeout,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('akeron-token')}`,
        }
    });
};

export const insertUser = async (name, address, telephone, email, userName, userPass, IdRol, IdSucursal) => {
    console.log()
    const secureclient = newSecureClient();
    console.log("LLEGA AL SERVICE INSERT");
    console.log(IdSucursal);
    if (IdSucursal == "") {
        IdSucursal = 0;
    }
    console.log(IdSucursal);
    return await secureclient.post(`/user/CreateUser`, {
        name, address, telephone, email, userName, userPass, IdRol, IdSucursal
    })
        .then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                console.log('error');
                throw new Error('No se pudo agregar el usuario');

            }
        }).catch((e) => { console.log(e); throw new Error(e.response.data.message); });
};

export const editUser = async (ID, name, address, telephone, email, userName, userPass, idRol, IdSucursal) => {
    const secureclient = newSecureClient();
    return await secureclient.put(`/user/UpdateUser`, {
        ID, name, address, telephone, email, userName, userPass, idRol, IdSucursal
    })
        .then(response => {
            if (response.status === 200) {
                console.log(response.data);
                return response.data;
            } else {
                throw new Error('No se pudo modificar el usuario');
            }
        }).catch((e) => { throw new Error(e.response.data.message); });
};

export const deleteUser = async (ID) => {

    const secureclient = newSecureClient();
    return await secureclient.post(`/user/DeleteUser`, {
        ID
    })
        .then(response => {
            if (response.status === 200) {
                return;
            } else {
                throw new Error('No se pudo eliminar el usuario');
            }
        }).catch((e) => { throw new Error(e.response.data.message); });
};

export const searchUserbyCompany = async () => {
    const secureclient = newSecureClient();
    return await secureclient.get(`/user/GetByCompany`, {})
        .then(response => {
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('No se pudo listar los usuarios');
            }
        }).catch((e) => { throw new Error(e.response.data.message); });
};