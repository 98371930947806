import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

// Service
import { searchSucursal } from '../services/SucursalService';
import { editUser } from '../services/UserService';

// Components
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import UpdateIcon from '@material-ui/icons/Create';
import FormControl from '@material-ui/core/FormControl';



const useStyles = makeStyles(theme => ({
    boxRobot: {
        height: 400
    },
    dialogWrapper: {
        margin: theme.spacing(2, 2, 8, 60),
        position: 'absolute',
        bottom: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        width: 400,
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1030,
    },
    paginate: {
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1)
    },
    select: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    container: {
        maxHeight: '70vh',
    },
    paper: {
        margin: theme.spacing(13, 2, 2, 60),
    },
    formsSelect: {
        margin: theme.spacing(1),
        minWidth: 365,


    },
}));

const ButtonEditUser = ({ user, OnEdit }) => {

    const [sucursales, setSucursales] = useState(null);
    const [ID, setID] = useState(user.ID);
    const [Name, setName] = useState(user.Name);
    const [Address, setAddress] = useState(user.Address);
    const [Telephone, setTelephone] = useState(user.Telephone);
    const [Email, setEmail] = useState(user.Email);
    const [IdRol, setRol] = useState(user.IdRol);
    const [IdSucursal, setSucu] = useState(user.IdSucursal);
    const [UserName, setUserName] = useState(user.UserName);
    const [UserPass, setUserPass] = useState(user.UserPass);
    const [openModal, setOpenModal] = useState(false);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const sendNotification = (variant, message) => {
        enqueueSnackbar(message, { variant });
    };

    const UpdateUsuario = async (evt) => {
        try {
            var dato = await editUser(ID, Name, Address, Telephone, Email, UserName, UserPass, IdRol, IdSucursal);
            
            const Type = dato.Type;
            const Sucursal = dato.Sucursal;
            

            OnEdit({ ID, Name, Address, Telephone, Email, UserName, UserPass, IdRol, IdSucursal, Type, Sucursal });
            setOpenModal(false);
            sendNotification('success', "Usuario modificado correctamente");
            return;

        } catch (e) {
            sendNotification('error', e.message);
        }
    }

    const Sucursales = async () => {

        try {
            const data = await searchSucursal();
            if (data !== null) {

                setSucursales(data);


            }
            else {
               sendNotification('info', 'No se pudieron cargar las sucursales');
            }
        } catch (e) {
            sendNotification('error', e.message);
        }



    };






    useEffect(() => {
        Sucursales();
    }, []);

    return (
        <>

            <IconButton
                aria-label="update"
                className={classes.margin}
                onClick={() => { handleOpenModal(user) }}
            >
                <UpdateIcon />
            </IconButton>

            <Dialog


                open={openModal}
                onClose={handleCloseModal}>
                <DialogTitle id="alert-dialog-title">{"Modificar Usuario"}</DialogTitle>
                <DialogContent>
                    <Grid container xs={10} justify="center" >
                        <Grid item xs={10}>

                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                id="name"
                                label="Nombre"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                value={Name}
                                onChange={(evt) => setName(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="address"
                                label="Direccion"
                                id="address"
                                autoComplete="address"
                                value={Address}
                                onChange={(evt) => setAddress(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                id="telephone"
                                label="Telefono"
                                name="telephone"
                                autoComplete="telephone"

                                value={Telephone}
                                onChange={(evt) => setTelephone(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="email"
                                label="Email"
                                id="email"
                                autoComplete="email"
                                value={Email}
                                onChange={(evt) => setEmail(evt.target.value)}
                            />
                            <FormControl className={classes.formsSelect}>
                                <InputLabel htmlFor="age-native-simple">Sucursal *</InputLabel>
                                {sucursales &&
                                    <Select
                                        native
                                        value={IdSucursal}
                                        color="secondary"
                                        fullWidth
                                        onChange={(evt) => setSucu(evt.target.value)}
                                        inputProps={{
                                            name: 'sucu',
                                            id: 'age-native-simple',
                                        }}
                                    >


                                        <option aria-label="None" value="" />
                                        {sucursales.map((sucursal, i) => {
                                            return (
                                                <option value={sucursal.ID}>{sucursal.Name}</option>
                                            )
                                        })}



                                    </Select>
                                }
                            </FormControl>

                            <FormControl className={classes.formsSelect}>
                                <InputLabel htmlFor="age-native-simple">Tipo *</InputLabel>
                                <Select
                                    native
                                    value={IdRol}
                                    onChange={(evt) => setRol(evt.target.value)}
                                    inputProps={{
                                        name: 'age',
                                        id: 'age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    <option value={2}>ADMINISTRADOR EMPRESA</option>
                                    <option value={3}>ADMINISTRADOR SUCURSAL</option>
                                    <option value={4}>USUARIO APP</option>
                                </Select>
                            </FormControl>

                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="username"
                                label="Usuario"
                                id="username"
                                autoComplete="username"
                                value={UserName}
                                onChange={(evt) => setUserName(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                type="password"
                                name="userpass"
                                label="Password"
                                id="userpass"
                                autoComplete="userpass"
                                value={UserPass}
                                onChange={(evt) => setUserPass(evt.target.value)}
                            />



                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        width="100"
                        justify="center"
                        variant="contained"
                        color="secondary"
                        onClick={() => { UpdateUsuario() }}

                    >
                        Modificar Usuario
                            </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
export default ButtonEditUser;