import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

// Service
import { searchSucursal} from '../services/SucursalService';
import { insertUser } from '../services/UserService';

// Components
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';



const useStyles = makeStyles(theme => ({
    boxRobot: {
        height: 400
    },
    dialogWrapper: {
        margin: theme.spacing(2, 2, 8, 60),
        position: 'absolute',
        bottom: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        width: 400,
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1030,
    },
    paginate: {
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1)
    },
    select: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    container: {
        maxHeight: '70vh',
    },
    paper: {
        margin: theme.spacing(13, 2, 2, 60),
    },
    formsSelect: {
        margin: theme.spacing(1),
        minWidth: 365,


    },
}));

const ButtonInsertUser = ({ OnEdit }) => {

    const [sucursales, setSucursales] = useState(null);
    
    const [Name, setName] = useState('');
    const [Address, setAddress] = useState('');
    const [Telephone, setTelephone] = useState('');
    const [Email, setEmail] = useState('');
    const [IdRol, setRol] = useState('');
    const [IdSucursal, setSucu] = useState('');
    const [UserName, setUserName] = useState('');
    const [UserPass, setUserPass] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const classes = useStyles();
    const rol = localStorage.getItem('akeron-rol');
    const [state, setState] = React.useState({
        Sucursal: '',
        Tipo: '',
    });
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const { enqueueSnackbar } = useSnackbar();

    const sendNotification = (variant, message) => {
        enqueueSnackbar(message, { variant });
    };


    const InsertUsuario = async (evt) => {
        try {
            console.log("LLEGA SIN ELEGIR SUCRUSAL");
            console.log(IdRol);
            console.log(IdSucursal);
            var dato = await insertUser(Name, Address, Telephone, Email, UserName, UserPass, IdRol, IdSucursal);
            const ID = dato.ID;
            OnEdit({ ID, Name, Address, Telephone, Email, UserName, UserPass, IdRol, IdSucursal});
            setOpenModal(false);
            sendNotification('success', "Usuario ingresado correctamente");
            return;

        } catch (e) {
            sendNotification('error', e.message);
        }
    }

    const Sucursales = async () => {
        
        try {
            const data = await searchSucursal();
            if (data !== null) {

                setSucursales(data);


            }
            else {
                sendNotification('info', 'No se pudieron cargar las sucursales');
            }
        } catch (e) {
            sendNotification('error', e.message);
        }

        

    };
    





    useEffect(() => {
        Sucursales();
        {(rol == 3) &&
            setSucu(0)
            setRol(4)
        }
    }, []);

    return (
        <>

            <Button
                color="secondary"
                variant="outlined"
                onClick={() => handleOpenModal()}
                className={classes.formControl}
            >
                Agregar
                    </Button>

            <Dialog


                open={openModal}
                onClose={handleCloseModal}>
                <DialogTitle id="alert-dialog-title">{"Agregar Usuario"}</DialogTitle>
                <DialogContent>
                    <Grid container xs={10} justify="center" >
                        <Grid item xs={10}>

                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                id="name"
                                label="Nombre"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                value={Name}
                                onChange={(evt) => setName(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="address"
                                label="Direccion"
                                id="address"
                                autoComplete="address"
                                value={Address}
                                onChange={(evt) => setAddress(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                id="telephone"
                                label="Telefono"
                                name="telephone"
                                autoComplete="telephone"

                                value={Telephone}
                                onChange={(evt) => setTelephone(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="email"
                                label="Email"
                                id="email"
                                autoComplete="email"
                                value={Email}
                                onChange={(evt) => setEmail(evt.target.value)}
                            />
                            {(rol == 2) &&
                                <FormControl className={classes.formsSelect}>
                                    <InputLabel htmlFor="age-native-simple">Sucursal *</InputLabel>
                                    {(sucursales) &&
                                        <Select
                                            native
                                            value={IdSucursal}
                                            color="secondary"
                                            fullWidth
                                            onChange={(evt) => setSucu(evt.target.value)}
                                            inputProps={{
                                                name: 'sucu',
                                                id: 'age-native-simple',
                                            }}
                                        >


                                            <option aria-label="None" value="" />
                                            {sucursales.map((sucursal, i) => {
                                                return (
                                                    <option value={sucursal.ID}>{sucursal.Name}</option>
                                                )
                                            })}



                                        </Select>
                                    }


                                </FormControl>
                            }
                            {(rol == 2) &&
                                <FormControl className={classes.formsSelect}>
                                    <InputLabel htmlFor="age-native-simple">Tipo *</InputLabel>
                                    <Select
                                        native
                                        value={IdRol}
                                        onChange={(evt) => setRol(evt.target.value)}
                                        inputProps={{
                                            name: 'age',
                                            id: 'age-native-simple',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value={2}>ADMINISTRADOR EMPRESA</option>
                                        <option value={3}>ADMINISTRADOR SUCURSAL</option>
                                        <option value={4}>USUARIO APP</option>
                                    </Select>
                                </FormControl>
                            }
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="username"
                                label="Usuario"
                                id="username"
                                autoComplete="username"
                                value={UserName}
                                onChange={(evt) => setUserName(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                type="password"
                                name="userpass"
                                label="Password"
                                id="userpass"
                                autoComplete="userpass"
                                value={UserPass}
                                onChange={(evt) => setUserPass(evt.target.value)}
                            />



                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        width="100"
                        justify="center"
                        variant="contained"
                        color="secondary"
                        onClick={() => { InsertUsuario() }}

                    >
                        Agregar Usuario
                            </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
export default ButtonInsertUser;