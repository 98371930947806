import React, { useState } from 'react';


// Components
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { insertCompany } from '../services/CompanyService';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    boxRobot: {
        height: 400
    },
    dialogWrapper: {
        margin: theme.spacing(2, 2, 8, 60),
        position: 'absolute',
        bottom: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        width: 400,
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1030,
    },
    paginate: {
        textAlign: 'center'
    },
    formControl: {
        margin: theme.spacing(1)
    },
    select: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    container: {
        maxHeight: '70vh',
    },
    paper: {
        margin: theme.spacing(13, 2, 2, 60),


    },
}));

const ButtonInsertCompany = ({ OnEdit }) => {


    const [Name, setName] = useState('');
    const [Address, setAddress] = useState('');
    const [Telephone, setTelephone] = useState('');
    const [Email, setEmail] = useState('');
    const [Contact, setContact] = useState('');
    const [NameUser, setNameUser] = useState('');
    const [UserName, setUserName] = useState('');
    const [UserPass, setUserPass] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const classes = useStyles();
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const InsertEmpresa = async (evt) => {
        try {
            var dato = await insertCompany(Name, Address, Telephone, Email, Contact, NameUser, UserName, UserPass);
            const ID = dato.ID;
            OnEdit({ ID, Name, Address, Telephone, Email, Contact });
            setOpenModal(false);
            return;

        } catch (e) {

        }
    }

    return (
        <>

            <Button
                color="secondary"
                variant="outlined"
                onClick={() => handleOpenModal()}
                className={classes.formControl}
            >
                Agregar
                    </Button>

            <Dialog


                open={openModal}
                onClose={handleCloseModal}>
                <DialogTitle id="alert-dialog-title">{"Agregar Empresa"}</DialogTitle>
                <DialogContent>
                    <Grid container xs={10} justify="center" >
                        <Grid item xs={10}>

                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                id="name"
                                label="Nombre"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                value={Name}
                                onChange={(evt) => setName(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="address"
                                label="Direccion"
                                id="address"
                                autoComplete="address"
                                value={Address}
                                onChange={(evt) => setAddress(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                id="telephone"
                                label="Telefono"
                                name="telephone"
                                autoComplete="telephone"
                                
                                value={Telephone}
                                onChange={(evt) => setTelephone(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="email"
                                label="Email"
                                id="email"
                                autoComplete="email"
                                value={Email}
                                onChange={(evt) => setEmail(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="contact"
                                label="Contacto"
                                id="contact"
                                autoComplete="contact"
                                value={Contact}
                                onChange={(evt) => setContact(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="nameuser"
                                label="Nombre usuario"
                                id="nameuser"
                                autoComplete="nameuser"
                                value={NameUser}
                                onChange={(evt) => setNameUser(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                name="username"
                                label="Usuario"
                                id="username"
                                autoComplete="username"
                                value={UserName}
                                onChange={(evt) => setUserName(evt.target.value)}
                            />
                            <TextField
                                margin="normal"
                                color="secondary"
                                required
                                fullWidth
                                type="password"
                                name="userpass"
                                label="Password"
                                id="userpass"
                                autoComplete="userpass"
                                value={UserPass}
                                onChange={(evt) => setUserPass(evt.target.value)}
                            />



                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        width="100"
                        justify="center"
                        variant="contained"
                        color="secondary"
                        onClick={() => { InsertEmpresa() }}

                    >
                        Agregar Empresa
                            </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}
export default ButtonInsertCompany;